<template>
  <div v-if="listSettings.records !== undefined">
    <div class="search-results">
      <div class="search-results__title">
        <h2 class="search-results__title" v-if="searchValue !== ''">
          Zoekresultaten voor "<span class="search-results__term" id="search-term"
                                     v-html="searchValue"></span>"
        </h2>
        <h2 class="search-results__title" v-if="searchValue === '' && features.length > 0">
          Alle zoekresultaten voor {{ listSettings.FormName }}
        </h2>
        <h2 class="search-results__title" v-if="features.length === 0">
          Bezig met ophalen van alle {{ listSettings.FormName }}...
        </h2>
      </div>
      <ul id="search-results" class="search-results__list">
        <li v-if="!isFeatureFound">
          Er zijn geen {{ listSettings.FormName }} gevonden
        </li>
        <li class="search-results__item"
            v-for="feature in filteredFeatures.slice(activePage * itemsPerPage, activePage * itemsPerPage + itemsPerPage)"
            :key="feature.id">
          <template v-for="(setting, index) in listSettings.records[0].attributes">
            <div :key="setting.ID">
              <template v-if="!detailSettings">
                  <h3 class="search-results__item-title" v-if="index === 0">
                    <em>
                      <template v-for="settingItem in setting">
                        {{ feature.properties[settingItem.Attribute] }}
                        <template v-for="setting2 in listSettings.records[0].attributes">
                          <template v-for="settingItem in setting2">
                            <template v-if="parseInt(settingItem.Type) === 21">
                              <template v-if="feature.properties.kv.geometry !== undefined">
                                ({{ (feature.properties.kv.geometry.value / 1000).toFixed(1) }} KM)
                              </template>
                            </template>
                          </template>
                        </template>
                      </template>
                    </em>
                  </h3>
              </template>
              <template v-else>
                <a
                    :href="getUrl([
                       { name: 'featureid', value: feature.id },
                     ])"
                    class="search-results__item-link" v-if="index === 0"
                    v-on:click="setActiveTab($event, 'list', feature, 'detail')">
                  <h3 class="search-results__item-title">
                    <em>
                      <template v-for="settingItem in setting">
                        {{ feature.properties[settingItem.Attribute] }}
                        <template v-for="setting2 in listSettings.records[0].attributes">
                          <template v-for="settingItem in setting2">
                            <template v-if="parseInt(settingItem.Type) === 21">
                              <template v-if="feature.properties.kv.geometry !== undefined">
                                ({{ (feature.properties.kv.geometry.value / 1000).toFixed(1) }} KM)
                              </template>
                            </template>
                          </template>
                        </template>
                      </template>
                    </em>
                  </h3>
                </a>
              </template>
              <div class="search-results__item-body">
                <template v-if="index > 0">
                  <template v-if="parseInt(listSettings.ShowEmptyFormAttributes) === 1">
                    <template v-if="[0, 1, 3, 402].includes(parseInt(setting[0].Type))">
                      {{ setting[0].DisplayName }} :
                    </template>
                    <template v-for="settingItem in setting">
                        <template v-if="parseInt(settingItem.Type) === 0">
                          {{settingItem.Prefix}}{{ feature.properties[settingItem.Attribute] }}{{settingItem.Suffix}}
                        </template>
                        <template v-else-if="parseInt(settingItem.Type) === 1">
                          <img v-bind:key="settingItem.ID" :src="settingItem.Prefix + feature.properties[settingItem.Attribute] + settingItem.Suffix" :title="settingItem.Prefix + feature.properties[settingItem.Attribute] + settingItem.Suffix">
                        </template>
                        <template v-else-if="parseInt(settingItem.Type) === 3">
                          <a v-bind:key="settingItem.ID" target="_blank" :href="settingItem.Prefix + feature.properties[settingItem.Attribute] + settingItem.Suffix">{{settingItem.Prefix}}{{ feature.properties[settingItem.Attribute] }}{{settingItem.Suffix}}</a>
                        </template>
                        <template v-else>
                          {{settingItem.Prefix}}{{ feature.properties[settingItem.Attribute] }}{{settingItem.Suffix}}
                        </template>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="feature.properties[setting[0].Attribute] !== null && feature.properties[setting[0].Attribute] !== ''">
                      <template v-if="[0, 1, 3, 402].includes(parseInt(setting[0].Type))">
                         {{ setting[0].DisplayName }} :
                      </template>
                      <template v-for="settingItem in setting">
                          <template v-if="parseInt(settingItem.Type) === 0">
                            {{settingItem.Prefix}}{{ feature.properties[settingItem.Attribute] }}{{settingItem.Suffix}}
                          </template>
                          <template v-else-if="parseInt(settingItem.Type) === 1">
                            <img v-bind:key="settingItem.ID" :src="settingItem.Prefix + feature.properties[settingItem.Attribute] + settingItem.Suffix" :title="settingItem.Prefix + feature.properties[settingItem.Attribute] + settingItem.Suffix">
                          </template>
                          <template v-else-if="parseInt(settingItem.Type) === 3">
                            <a v-bind:key="settingItem.ID" target="_blank" :href="settingItem.Prefix + feature.properties[settingItem.Attribute] + settingItem.Suffix">{{settingItem.Prefix}}{{ feature.properties[settingItem.Attribute] }}{{settingItem.Suffix}}</a>
                          </template>
                          <template v-else-if="parseInt(setting[0].Type) === 402">
                            {{settingItem.Prefix}}{{ feature.properties[settingItem.Attribute] }}{{settingItem.Suffix}}
                          </template>
                      </template>
                    </template>
                  </template>
                </template>
              </div>
            </div>
          </template>
        </li>
      </ul>
    </div>
    <div class="search-results__pagination" v-if="features.length > 0">
      <nav aria-label="Pagina navigation (static)">
        <ul class="pagination">
          <li class="page-item page-item-previous" :class="{disabled:activePage === 0}">
            <a
                :href="getUrl([{ name: 'page', value: activePage}])"
                class="page-link waves-effect waves-effect" aria-disabled="true" v-on:click="goToPreviousPage($event)">
                 <span aria-hidden="true">
                   <i class="mdi mdi-chevron-left" aria-hidden="true"></i>
                 </span>
              <span class="sr-only">Vorige <template v-if="activePage === 0">is niet mogelijk</template></span>
            </a>
          </li>
          <li class="page-item" v-for="n in pageNumbers" v-bind:key="n"
              :class="{active:activePage === (n - 1)}"
              v-on:click="goToPage(n, $event)">
            <a :href="getUrl([{ name: 'page', value: n}])" class="page-link" v-if="n <= totalPageNumbers()">
              {{ n }}
              <span class="sr-only" v-if="activePage === (n - 1)">huidig</span>
            </a>
          </li>
          <li class="page-item page-item-next" :class="{disabled:activePage === (totalPageNumbers() - 1)}">
            <a class="page-link waves-effect waves-effect"
               :href="getUrl([{ name: 'page', value: activePage + 2}])"
               aria-disabled="false" v-on:click="goToNextPage($event)">
                <span aria-hidden="true">
                  <i class="mdi mdi-chevron-right" aria-hidden="true"></i>
                </span>
                <span class="sr-only">
                  Volgende <template v-if="activePage === (totalPageNumbers() - 1)">is niet mogelijk</template>
                </span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import ListMixin from "@/mixins/ListMixin";
import PaginationMixin from "@/mixins/PaginationMixin";

export default {
  name: "ListNijmegen",
  mixins: [
      ListMixin,
      PaginationMixin
  ]
};
</script>


