<template>

  <autocomplete ref="autocompleteLocation" :get-result-value="getResultValue" :search="searchLocation" :debounce-time="500" @submit="onSubmitLocation">

    <template
        #default="{
    rootProps,
    inputProps,
    inputListeners,
    resultListProps,
    resultListListeners,
    results,
    resultProps
  }"
    >
      <label for="gn-autocomplete-location">Zoek in de buurt van</label>
      <div class="autocomplete autocomplete__form" v-bind="rootProps" role="search">
        <input
            type="text"
            class="autocomplete__input form-control"
            v-bind="inputProps"
            v-on="inputListeners"
            id="gn-autocomplete-location"
            name="gn-search-location-q"
            @keyup="onkeyupLocation"
            placeholder="Begin met typen..."
            autocomplete="off"
            aria-controls="autocomplete-results"
            aria-autocomplete="both"
            aria-label="Zoek in de buurt van"
            aria-describedby="autocomplete-help-text-filter"
            ref="searchInputLocation"
        />
        <button v-on:click="askForLocation" class="btn-info autocomplete__search-button" style="border:0;background-color:unset;left: -35px;">
          <span class="sr-only">Haal door middel van uw gps de locatie up</span><span class="mdi mdi-map-marker" aria-hidden="true"></span>
        </button>

        <button v-if="keyUpLocationValue !== ''" v-on:click="clearLocation" class="btn-info autocomplete__search-button" style="border:0;background-color:unset;right:-35px">
          <span class="sr-only">Maak de tekstbox voor het verfijnen van uw resultaat leeg</span>
          <span class="mdi mdi mdi-close" aria-hidden="true"></span>
        </button>

        <button v-if="keyUpLocationValue === ''" class="btn-info autocomplete__search-button" style="border:0;background-color:unset;right:-35px">
          <span class="sr-only">Maak de tekstbox voor het verfijnen van uw resultaat leeg</span>
          <span class="mdi mdi-magnify" aria-hidden="true"></span>
        </button>

        <ul class="autocomplete__result-list" aria-label="Zoekresultaten" role="listbox" v-bind="resultListProps" v-on="resultListListeners">
          <li class="autocomplete__section-header" aria-hidden="true">Zoekresultaten:</li>
          <li
              class="autocomplete__result-item"
              role="option"
              v-for="(result, index) in results"
              :key="resultProps[index].value"
              v-bind="resultProps[index]"
          >
            <a
                @click="onClick"
                :href="getUrl([
                       { name: 'q', value: result },
                     ])"
                ref="autocompletelinklocation" class="autocomplete__result-link" tabindex="-1">{{ result.value }}</a>
          </li>
        </ul>

        <span id="autocomplete-help-text-filter" class="sr-only">
            Als er zoekresultaten gevonden zijn kunt u de pijltjes toetsen omhoog en naar beneden gebruiken om een resultaat
            te selecteren. Toets enter om te navigeren naar het geselecteerde resultaat. Met de escape toets kunt u de
            invoer wissen.
          </span>
      </div>
    </template>
  </autocomplete>
</template>

<script>

import SearchLocationMixin from "@/mixins/SearchLocationMixin";
import Autocomplete from "@trevoreyre/autocomplete-vue";

export default {
  name: "SearchLocationNijmegen",
  mixins: [
    SearchLocationMixin,
  ],
  components: {
    Autocomplete
  },
  computed: {
    features() {
      return this.$store.getters.getFeatures
    },
    filteredFeatures() {
      return this.$store.getters.getFilteredFeatures
    },
    kaartViewerUrl() {
      return this.$store.getters.getKaartViewerUrl
    },
    settings() {
      return this.$store.getters.getSettings
    }
  },
  methods: {
    searchLocation(input) {

      const cbsCodes = this.settings.gemeente.code
      const url = this.kaartViewerUrl + `/admin/rest/routeapi/location?cbsCodes=${cbsCodes}&query=${encodeURI(input)}`

      return new Promise((resolve) => {
        if (input.length < 3) {
          return resolve([])
        }

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
              resolve(data.suggestions)
            })
      })
    },
    onSubmitLocation(result) {

      this.searchForLocation(result)

      this.keyUpLocationValue = this.getResultValue(result)

      this.searchLocationValue = result
    },
    getResultValue(result) {
      return result.value
    },
    setValue(value) {

      const feature = {}
      feature.value = value

      this.$refs.autocompleteLocation.setValue(feature)
    },
    onClick(e) {
      e.preventDefault()
    },
    mounted() {
      this.$refs.searchInputLocation.removeAttribute('role');
    },
    onkeyupLocation(result) {
      if(result.target.value === '') {
        this.keyUpLocationValue = ''
      } else {
        this.keyUpLocationValue = result.target.value
      }

      if(this.$refs.autocompletelinklocation !== undefined) {
        this.$refs.autocompletelinklocation.map((element) => {
          if(
              element.parentElement.getAttribute('aria-selected') === 'true')
          {
            // //console.log('make active')
            element.classList.value = ['autocomplete__result-link active']
          } else {
            element.classList.value = ['autocomplete__result-link']
          }
        });
      }
    },
    clearLocation() {
      this.keyUpLocationValue = ''
      this.$refs.autocompleteLocation.setValue('')
      this.$store.commit('setGeoLocation', null);

      this.filteredFeatureCopy = this.filteredFeatures
      this.filteredFeatureCopy.map(feature => {
        this.addDistance(feature, null)
      });

      this.$store.commit('setFilteredFeatures', this.filteredFeatures)
    }
  }
};
</script>
