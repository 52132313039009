<template>
  <div class="facets-advanced" id="facets" role="navigation" v-if="filterSettings !== null && filterSettings.records !== undefined && features.length > 0">

    <h2 class="facets__title" :class="{expanded:isFacetTitleVisible}" v-on:click="toggleFacetTitle">Filter resultaten</h2>

    <div class="facets__facets hide" :class="{expanded:isFacetTitleVisible}">

      <div class="md-form" v-if="getSortingItems().length > 1">
        <div class="aria-select" @keydown="onkeydown">
          <button :aria-expanded="isSortDropDownOpen" @click="toggleSortDropDown('')" aria-haspopup="true" aria-controls="facet-sort-select-list" aria-labelledby="facet-sort-select-button facet-sort-select-label" id="facet-sort-select-button" style="text-wrap: nowrap">
            {{sortByAttribute.DisplayName}}
          </button>
          <label id="facet-sort-select-label" for="facet-sort-select-button">Sorteren op</label>
          <ul :class="{hidden:!isSortDropDownOpen}" style="opacity:1" tabindex="-1" role="listbox" aria-labelledby="facet-sort-select-element" class="aria-select-list" id="facet-sort-select-list">
            <li ref="filtersortlistitem" v-for="(sortingItem, index) in getSortingItems()" v-bind:key="sortingItem.ID"  @click="toggleSortDropDown(sortingItem)" id="facet-sort-select-element" :class="sortByAttribute.DisplayName === sortingItem.DisplayName ? 'focused' : ''" :data-value="index" role="option" :aria-selected="sortByAttribute.DisplayName === sortingItem.DisplayName">
              {{sortingItem.DisplayName}}
            </li>
          </ul>
        </div>
      </div>

      <div class="facets__group" :class="isExpanded(index)" v-for="(setting, index) in filterSettings.records[0].attributes" :key="setting[0].ID" v-show="isGeometryLoaded(setting)">
        <template v-if="parseInt(setting[0].MutationTypeID) === 1023 || parseInt(setting[0].MutationTypeID) === 1022 || parseInt(setting[0].Type) === 21">
        <button class="facets__group-button" :aria-controls="'facet-group-list-' + index" :aria-expanded="isAriaExpanded(index)" v-on:click="toggleFacetGroup($event, index)">
          <h2 class="facets__group-title">{{ setting[0].DisplayName }}</h2>
        </button>

        <ul :id="'facet-group-list-' + index" class="facets__list" v-show="openFacetGroups.includes(index)">
          <template v-for="filterItem in getFilterItems">
            <template v-if="filterItem.ID === setting[0].ID">
              <template v-for="feature in getSortedFeatures(filterItem)">
<!--              <template v-for="feature in getSortedFeatures(filterItem)">-->

                <template v-if="setting[0].MutationTypeID !== 1023">
                  <template v-if="parseInt(setting[0].Type) === 402 || parseInt(setting[0].Type) === 403 || parseInt(setting[0].Type) === 21">
                    <li class="facets__item" :key="feature.id"
                        :class="{active:activeSetting === feature.properties.kv[setting[0].Attribute].name}">
                      <a class="facets__link"
                         :href="getUrl([
                             { name: 'facetName', value: setting[0].DisplayName },
                             { name: 'facetValue', value: feature.properties.kv[setting[0].Attribute].name }
                           ])"
                         v-on:click="filter($event, setting[0], feature.properties.kv[setting[0].Attribute], setting[0].DisplayName)">
                        {{ feature.properties.kv[setting[0].Attribute].name }}
                        <span>({{ feature.counter }})</span>
                      </a>
                    </li>
                  </template>
                  <template v-if="parseInt(setting[0].Type) === 0"> <!-- && setting[0].Attribute !== 'GEZICHTNAAM'"> -->
                    <li class="facets__item" :key="feature.id"
                        :class="{active:activeSetting === feature.properties[setting[0].Attribute]}">
                      <a class="facets__link"
                         :href="getUrl([
                             { name: 'facetName', value: setting[0].DisplayName },
                             { name: 'facetValue', value: feature.properties[setting[0].Attribute] }
                           ])"
                         v-on:click="filter($event, setting[0], feature.properties[setting[0].Attribute], setting[0].DisplayName)">
                        {{ feature.properties[setting[0].Attribute] }}
                        <span>({{ feature.counter }})</span>
                      </a>
                    </li>
                  </template>
                </template>
                <template v-if="setting[0].MutationTypeID === 1023 && feature && feature.properties && feature.properties[setting[0].Attribute] && feature.properties[setting[0].Attribute] !== null">
                  <li class="facets__item"  :key="feature.id">
                    <div class="form-check" v-on:click="filter($event, setting[0], feature.properties[setting[0].Attribute], setting[0].DisplayName, 'Nijmegen')">
                      <input type="checkbox"
                             class="facets__checkbox form-check-input"
                             :id="'facet-item-checkbox-' + filterItem.ID + '-' + feature.id"
                             :checked="activeFilterCheckedClass(feature.properties[setting[0].Attribute], filterItem.ID)"
                      >
                      <label :for="'facet-item-checkbox-' + filterItem.ID + '-' + feature.id"
                             class="facets__label form-check-label">
                        {{ feature.properties[setting[0].Attribute] }}
                        <span>({{ feature.counter }})</span>
                      </label>
                    </div>
                  </li>
                </template>
              </template>
            </template>
          </template>
        </ul>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import FilterMixin from '@/mixins/FilterMixin'

export default {
  name: "FilterNijmegen",
  mixins: [
      FilterMixin
  ],
  methods: {
    onkeydown(e) {
      console.log('keyup')

      if(["Space","ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(e.code) > -1) {
        e.preventDefault();
      }

      if(e.code === 'Tab') {
        this.isSortDropDownOpen = false
      }

      if(e.code === 'Enter') {
        let dropDownItemIndex
        this.$refs.filtersortlistitem.map((element) => {
          if(
              element.classList.contains('focused'))
          {
            dropDownItemIndex = element.dataset.value
          }
        })

        console.log(dropDownItemIndex)

        if(dropDownItemIndex) {
          this.toggleSortDropDown(this.getSortingItems()[dropDownItemIndex])

          this.isSortDropDownOpen = !this.isSortDropDownOpen
        }
      }

      else {
        if(this.$refs.filtersortlistitem !== undefined) {

          const activeIndex = this.$refs.filtersortlistitem.findIndex(element => element.classList.contains('focused'))

          this.$refs.filtersortlistitem[activeIndex].setAttribute('aria-select', 'false')
          this.$refs.filtersortlistitem[activeIndex].classList.value = []

          let nextElement
          if(e.code === 'ArrowUp') {
              nextElement = this.$refs.filtersortlistitem[activeIndex - 1]
              if(!nextElement) {
                nextElement = this.$refs.filtersortlistitem[this.$refs.filtersortlistitem.length - 1]
              }
          }

          if(e.code === 'ArrowDown') {
            nextElement = this.$refs.filtersortlistitem[activeIndex + 1]
            if(!nextElement) {
              nextElement = this.$refs.filtersortlistitem[0]
            }
          }

          if(!nextElement) {
             nextElement = this.$refs.filtersortlistitem[0]
          }
          nextElement.classList.value = ['focused']
        }
      }

      // return false

    },
  }
}
</script>


