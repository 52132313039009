<template>
    <div id="gn">
      <section class="gn-row">
        <template v-if="layoutCombi === 1">
          <aside :class="'gn-col-' + splitLeft" v-show="splitLeft > 0">
            <search-geo-novation v-if="hideSearch === 0"/>
            <list-geo-novation v-show="activeSubTab !== 'detail'"/>
            <detail-geo-novation v-if="activeSubTab === 'detail'"/>
            <filter-geo-novation v-show="false"/>
          </aside>

          <div :class="'gn-col-' + splitRight" v-show="splitRight > 0">
            <template v-if="mounted === 1">
              <search-location-geo-novation v-if="hideLocationSearch === 0"/>
              <map-view/>
            </template>
          </div>
        </template>
        <template v-else>
          <aside :class="'gn-col-' + splitLeft" v-show="splitLeft > 0">
            <search-geo-novation v-if="hideSearch === 0"/>
            <filter-geo-novation/>
          </aside>
          <div :class="'gn-col-' + splitRight" v-show="splitRight > 0">
            <switch-geo-novation/>
          </div>
        </template>
      </section>
    </div>
</template>

<script>
import FilterGeoNovation from "@/layout/GeoNovation/FilterGeoNovation";
import SwitchGeoNovation from "@/layout/GeoNovation/SwitchGeoNovation";
import SearchGeoNovation from "@/layout/GeoNovation/SearchGeoNovation";
import ListGeoNovation from "@/layout/GeoNovation/ListGeoNovation";
import MapView from "@/views/MapView";
import DetailGeoNovation from "@/layout/GeoNovation/DetailGeoNovation";
import SearchLocationGeoNovation from "@/layout/GeoNovation/SearchLocationGeoNovation";

export default {
    name: 'Layout',
    data() {
      return {
        mounted: 0
      };
    },
    components: {
      SearchLocationGeoNovation,
      DetailGeoNovation,
      ListGeoNovation,
      SearchGeoNovation,
      SwitchGeoNovation,
      FilterGeoNovation,
      MapView
    },
    created() {
        if(this.mapFirst === 1) {
          // this.$store.commit('setActiveTab', 'list')
          this.$store.commit('setActiveTab', 'map')
          this.$store.commit('isMapMounted', true);
        } else {
          this.$store.commit('setActiveTab', 'list')
        }
    },
    mounted() {
      // setTimeout(() => {
      this.mounted = 1
      // }, 300);
    },
    computed: {
      activeTab() {
        return this.$store.getters.getActiveTab
      },
      layoutCombi() {
        return this.$store.getters.getLayoutCombi
      },
      activeSubTab() {
        return this.$store.getters.getActiveSubTab
      },
      hideSearch() {
        return this.$store.getters.getHideSearch
      },
      hideLocationSearch() {
        return this.$store.getters.getHideLocationSearch
      },
      activeFeature() {
        return this.$store.getters.getActiveFeature
      },
      splitLeft() {
        return this.$store.getters.getSplitLeft
      },
      splitRight() {
        return this.$store.getters.getSplitRight
      },
      mapFirst() {
        return this.$store.getters.getMapFirst
      }
    }
}
</script>

<style>
/* General helpers */
.gn-disabled, .gn-disabled a{
  pointer-events: none;
  cursor: auto;
}
.gn-sr-only{
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}

/* Grid */
@media (min-width: 992px) {
  .gn-row{
    display: flex;
    flex-wrap: wrap;
  }
  .gn-row .gn-col-1 { flex: 0 0 8.3333333333%; 	max-width: 8.3333333333%;  }
  .gn-row .gn-col-2 { flex: 0 0 16.6666666667%; 	max-width: 16.6666666667%; }
  .gn-row .gn-col-3 { flex: 0 0 25%; 				max-width: 25%;			   }
  .gn-row .gn-col-4 { flex: 0 0 33.3333333333%; 	max-width: 33.3333333333%; }
  .gn-row .gn-col-5 { flex: 0 0 41.6666666667%; 	max-width: 41.6666666667%; }
  .gn-row .gn-col-6 { flex: 0 0 50%; 				max-width: 50%;			   }
  .gn-row .gn-col-7 { flex: 0 0 58.3333333333%; 	max-width: 58.3333333333%; }
  .gn-row .gn-col-8 { flex: 0 0 66.6666666667%; 	max-width: 66.6666666667%; }
  .gn-row .gn-col-9 { flex: 0 0 75%; 				max-width: 75%;			   }
  .gn-row .gn-col-10{ flex: 0 0 83.3333333333%; 	max-width: 83.3333333333%; }
  .gn-row .gn-col-11{ flex: 0 0 91.6666666667%; 	max-width: 91.6666666667%; }
  .gn-row .gn-col-12{ flex: 0 0 100%;				max-width: 100%;		   }
}

/* Tabs */
#gn div[role="tabpanel"] {
  position: relative;
  z-index: 2;
}

/* Facets / Collapse */
.gn-facet-title{
  position: relative;
}
.gn-pagination{
  list-style-type: none;
  display: flex;
}

/* extra styling (not necessary)  */
.gn-row{
  margin: 15px 0;
}
.gn-icon:before{
  display: inline-block;
  font-size: inherit;
  text-rendering: auto;
}
.gn-icon-left:before{ content: "\1F844";}
.gn-icon-right:before{ content: "\1F846";}
.gn-icon-up:before{ content: "\1F845";}
.gn-icon-down:before{ content: "\1F847";}
.gn-icon-collapse::before{ content: "\1F845"; }
.gn-toggle[aria-expanded="false"] .gn-icon-collapse::before{ content: "\1F847"; }

.gn-disabled a{ color: grey; }

/*#gn {*/
/*  background-color: ghostwhite;*/
/*  width: 1000px;*/
/*  margin: 0 auto;*/
/*  padding: 30px;*/
/*}*/

#gn-list-results{
  list-style-type: none;
  padding-left: 0;
}
#gn-list-results li{
  margin-bottom: 5px;
}
#gn-list-results h3{
  margin: 0;
}

#gn-list-details {
   margin-top:20px;
}

#gn-list-results {
  margin-left: 0px;
  margin-top: 15px;
}

table.gn-table{
  border: 0;
  text-align: left;
  border-spacing: 0;
  margin-bottom: 0px;
}

.gn-pagination li a{ padding: 5px;}
#gn input{ padding: 4px; }

#gn button{
  border: 1px solid black;
  padding: 5px 15px;
}
#gn button:hover,
#gn button:focus{
  outline: none;
  box-shadow: 0 0 0 2px rgba(0,0,0,0.75); /* Some form of styling is required, to differentiate */
}

#gn button[role="tab"][aria-selected="true"] {
  background-color: black;  /* Some form of styling is required, to differentiate */
  color: white;
}
#gn [role="tabpanel"]{
  /*border: 1px solid black;*/
  /*padding: 0 15px;*/
}
#gn div[role="tabpanel"]:focus {
  box-shadow: 0 0 0 2px black;  /* Some form of styling is required, to differentiate */
}

#gn div[role="tabpanel"]:focus {
  box-shadow: unset;  /* Some form of styling is required, to differentiate */
}


#gn #gn-form-search-location {
  float: right;
}

#gn #map-leaflet-map {
  margin-top: 15px;
  margin-bottom: 15px;
}

#gn #gn-search-results, #gn #gn-locationsearch-results {
  background: #fff;
  box-shadow: 3px 3px 6px rgba(0,0,0,.25),0 0 15px rgba(0,0,0,.15);
  z-index: 4001 !important;
}

#gn .gn-autocomplete-result-link.active, .gn-autocomplete-result-link:focus {
  background-color: #6b6b6b;
  color: #fff;
  cursor: pointer;
  -webkit-transition: none;
  transition: none;
}

#gn .gn-facet-list-item.active {
  font-weight: bold;
}

#gn .md-toast-close-button {
  display: none;
}

#gn .gn-fullscreen {
  margin-left: 5px;
}

#gn .gn-table img { width:100%; }
#gn .popover {
  position: static; display: block !important;  max-width: 350px !important;
}

#gn .leaflet-control-scale-line {
  overflow: unset;
}
</style>
