
export default {
    props: {
        method: Function
    },
    data() {
        return {
            activeLayerButtonIndex: 0,
            visibleMap: ''
        }
    },
    computed: {
        settings() {
            return this.$store.getters.getSettings
        }
    },
    created() {
        this.visibleMap = this.settings.achtergrondkaarten[0].naam
    },
    methods: {
        setMap(layer) {

            console.log(layer)

            this.visibleMap = layer
            this.method(layer)
        },
        clickKey(event) {

            let activeLayerButtonIndex = this.activeLayerButtonIndex
            let totalLayerButtons = this.$refs['layer-button'].length

            //console.log(activeLayerButtonIndex)

            // Left
            if(event.keyCode == 37) {
                activeLayerButtonIndex = activeLayerButtonIndex - 1;
                // Right
            } else if(event.keyCode == 39) {
                activeLayerButtonIndex = activeLayerButtonIndex + 1;
            } else {
                // activeLayerButtonIndex = 0;
            }

            //console.log(activeLayerButtonIndex)

            if(activeLayerButtonIndex >= 0 && activeLayerButtonIndex < totalLayerButtons) {

                //console.log('set focus ' + activeLayerButtonIndex)

                this.$refs['layer-button'][activeLayerButtonIndex].focus();
                this.$refs['layer-button'][activeLayerButtonIndex].checked = true
                this.activeLayerButtonIndex = activeLayerButtonIndex
            }
        },
        btnShowBackgroundLayer(layer) {

            let showBackgroundLayer = true;


            const layers = [];
            let selectedIndex = 0;
            this.settings.achtergrondkaarten.map( (value, index) => {
                if(value.naam === layer.naam) {
                    layers.push(layer);
                }
                if(value.id === layer.id) {
                    selectedIndex = index;
                }
            });

            if(layers.length > 1 && selectedIndex === 0) {
                showBackgroundLayer = false;
            }

            return showBackgroundLayer;
        }
    }
}
